import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import {
	handleErrorWithSentry,
	httpClientIntegration,
	replayIntegration,
} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';
import { goto } from '$app/navigation';
import * as publicEnv from '$env/static/public';
import { getBiometricLockService } from '$lib/services/biometric-lock.service';

const enableSentry = publicEnv.PUBLIC_ENABLE_SENTRY === 'true';
if (enableSentry) {
	Sentry.init({
		environment: publicEnv.PUBLIC_ENV_CONFIG ?? 'dev',
		dsn: publicEnv.PUBLIC_SENTRY_DSN ?? '',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration(), httpClientIntegration()],
	});
}

export async function init(): Promise<void> {
	if (Capacitor.isNativePlatform()) {
		const biometricLockService = await getBiometricLockService();

		const navigateToUnlockIfBiometricsEnabled = async () => {
			if (get(biometricLockService.lockState).status === 'locked') {
				if (window.location.pathname !== '/biometric-unlock') {
					await goto('/biometric-unlock');
				}
			}
		};

		await App.addListener('resume', navigateToUnlockIfBiometricsEnabled);
		await navigateToUnlockIfBiometricsEnabled();
	}
}

// init function only supported from version 2.10 so we need to patch untill we update
let initialised = false;
if (initialised === false) {
	init().then(
		() => {
			initialised = true;
		},
		(error) => {
			const { name, message, cause } = error;
			console.error('initialising', { name, message, cause });
		},
	);
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
